<template>
  <div class="home-class">
    <div class="power-station">
      <!-- 头部部分 -->
      <div ref="search_d" style="margin-bottom: 10px">
        <el-form :inline="true" :model="queryInfo.condition" class="top-form-inline">
          <div class="top-form">
            <div>
              <el-button type="primary" @click="newBtn">新建</el-button>
              <exportBtn :params="expParams"/>
            </div>
            <div>
              <el-form-item label="设备类型">
                <el-select
                  v-model="queryInfo.condition.deviceTypeCode"
                  placeholder="请选择设备类型"
                  clearable

                >
                  <el-option
                    v-for="item in deviceTypeList"
                    :key="item.id"
                    :label="item.deviceTypeName"
                    :value="item.deviceTypeCode"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="告警类型">
                <el-select v-model="queryInfo.condition.signalType" placeholder="请选择告警类型" clearable filterable>
                  <el-option v-for="item in signalList" :key="item.id" :label="item.signalName"
                    :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="search">查询</el-button>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </div>

      <!-- 表格部分 -->
      <div>
        <el-table
          border
          ref="table"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          height="75vh"
        >
          <el-table-column label="操作" width="135" align="center" fixed>
            <template slot-scope="scope">
              <el-button type="text" @click="edit(scope.row,true)" size="small"
                >详情</el-button
              >
              <el-button type="text" @click="edit(scope.row)" size="small"
                >编辑</el-button
              >
              <el-button
                slot="reference"
                type="text"
                @click="handleDelte(scope.row.id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
          <el-table-column
            label="序号"
            type="index"
            width="80"
            align="center"
          ></el-table-column>

          <el-table-column label="设备类型" min-width="180" show-overflow-tooltip align="center">
            <template slot-scope="scope">{{
              scope.row.deviceTypeName
            }}</template>
          </el-table-column>
          <el-table-column label="告警类型" min-width="180" show-overflow-tooltip align="center">
            <template slot-scope="scope">{{
              scope.row.signalName
            }}</template>
          </el-table-column>
          <el-table-column label="解决方案" min-width="250" show-overflow-tooltip align="center">
            <template slot-scope="scope">{{ scope.row.dealMessage }}</template>
          </el-table-column>
   
        </el-table>
        <!-- 分页 -->
        <div class="block">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="queryInfo.currPage"
            :page-sizes="[100, 200, 300, 400]"
            :page-size="queryInfo.pageSize"
            class="pagination-style"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>

      <el-dialog
        :title="labelType == 'add' ? '新增' : editable?'详情':'编辑'"
        :visible.sync="showDialog"
        width="800px"
      >
        <el-form
          class="form-class"
          ref="form"
          :model="form"
          :rules="rules"
          :disabled="editable"
          label-width="120px"
        >
          <el-row :gutter="10">
            <el-col :span="24">
              <el-form-item label="设备类型" prop="deviceTypeCode">
                <el-select
                  v-model="form.deviceTypeCode"
                  placeholder="请选择设备类型"
                  clearable
                  @change="deviceTypeChange"
                >
                  <el-option
                    v-for="item in deviceTypeList"
                    :key="item.id"
                    :label="item.deviceTypeName"
                    :value="item.deviceTypeCode"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="告警类型" prop="signalType">
                <el-select v-model="form.signalType" placeholder="请选择告警类型" clearable filterable :disabled="dselected">
                  <el-option v-for="item in signalListByd" :key="item.id" :label="item.signalName"
                    :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="解决方案" prop="dealMessage">
                <el-input
                  :autosize="{ minRows: 5}"
                  type="textarea"
                  v-model="form.dealMessage"
                  placeholder="请输入解决方案"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <span slot="footer" v-if="!editable">
          <el-button size="small" @click="showDialog = false">取消</el-button>
          <el-button size="small" type="primary" @click="handleSave('form')"
            >确定</el-button
          >
        </span>
      </el-dialog>
    </div>

    <el-image-viewer
      v-if="showViewer"
      :zIndex="8000"
      :on-close="closeViewer"
      :url-list="previewImgList"
    />
  </div>
</template>

<script>
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import exportBtn from '@/components/exportBtn.vue'
export default {
  components: { ElImageViewer,exportBtn },
  data() {
    return {
      labelType: "add",
      multipleSelection: [], //选中列表
      loading: true,
      deptName: "",
   
      rules: {},
      total: 0,
      queryInfo: {
        pageSize: 100,
        currPage: 1,
        condition: {
          signalType:'',
          deviceTypeCode:''
        },
      },
      form: {},
      showDialog: false,
      tableData: [],
      height: window.innerHeight - 230, //表格高度
      headerHeight: 0,
      showViewer: false,
      previewImgList: [],
      signalList: [], //告警类型
      deviceTypeList: [], //设备类型
      signalListData: {}, //告警类型取值
      deviceTypeListData: {}, //设备类型取值
      signalListByd: [], //根据设备告警类型
      editable:false,
      dselected:false,
      expParams:{
        url:'',
        fileName:''
      },
    };
  },

  created() {
    this.getList();
    this.getSelectList()
  },

  mounted() {
    this.$nextTick(() => {
      // 获取定义ref属性 和 元素高度
      this.headerHeight = this.$refs.search_d.offsetHeight;
      window.addEventListener("resize", this.getHeight());
    });
  },

  methods: {
    //图片上传成功处理
    handleUploadSuccess(res, file) {
      this.form.logo = file.response.data.url;
    },
    // 查看图片
    showImg(item) {
      this.previewImgList = [];
      this.showViewer = true;
      this.previewImgList = [item];
    },
    // 关闭图片
    closeViewer() {
      this.showViewer = false;
    },
    // 监听表格高度 256
    getHeight() {
      this.height = window.innerHeight - this.headerHeight - 190;
    },
    search() {
      this.getList()
    },
    //获取数据
    getList() {
      var that = this;
      that.$http
        .post("/deviceAlarmSuggestion/list", that.queryInfo)
        .then(function (response) {
          console.log(response.data.data);
          if (response.data.code == 200) {
            that.total = response.data.data.count;
            that.queryInfo.currPage = response.data.data.currPage;
            that.tableData = response.data.data.data;
          }
        });
    },
    getSelectList() {
      // 告警类型
      this.$http
        .post("deviceAlarmDefinition/queryAlarmType", {})
        .then((res) => {
          if (res.data.code == 200) {
            this.signalList = res.data.data;
            this.signalList.forEach(item => {
              this.signalListData[item.id] = item.signalName
            })
          } else {
            this.$message.error(res.data.message);
          }
        });

      // 设备类型
      this.$http
        .post("deviceAlarmDefinition/queryDeviceType", {})
        .then((res) => {
          if (res.data.code == 200) {
            this.deviceTypeList = res.data.data;
            this.deviceTypeList.forEach(item => {
              this.deviceTypeListData[item.deviceTypeCode] = item.deviceTypeName
            })
          } else {
            this.$message.error(res.data.message);
          }
        });
    },
    deviceTypeChange(val){
      if(val){
        this.signalListByd = []
        this.$http
        .post("deviceAlarmDefinition/queryAlarmType", {
          deviceTypeCode: val
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.$set(this.form,'signalType','')
            this.signalListByd = res.data.data;
          } else {
            this.$message.error(res.data.message);
          }
        });
      }else{
        this.$set(this.form,'signalType','')
      }
    },
      
    // 保存
    handleSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var that = this;

          that.$http
            .post("deviceAlarmSuggestion/save", {
              ...that.form,
              signalName: that.signalListData[that.form.signalType],
              deviceTypeName: that.deviceTypeListData[that.form.deviceTypeCode]
            })
            .then(function (response) {
              if (response.data.code == 200) {
                that.$notify.success({
                  title: "提示",
                  message: "保存成功",
                  showClose: true,
                });
                that.signalListByd = []
                that.showDialog = false;
                that.getList();
              } else {
                that.$notify.info({
                  title: "提示",
                  message: response.data.message,
                  showClose: true,
                });
              }
            })
            .catch(function (error) {});
        }
      });
    },

    //修改
    edit(row,boolean) {
      this.showDialog = true;
      this.labelType = "edit";
      this.editable = boolean?true:false
      // 详情
      this.$http
        .post("deviceAlarmSuggestion/queryOne", {
          id: row.id
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.$http
            .post("deviceAlarmDefinition/queryAlarmType", {
              deviceTypeCode: res.data.data.deviceTypeCode
            })
            .then((response) => {
              if (response.data.code == 200) {
                this.signalListByd = response.data.data;
              } else {
                this.$message.error(response.data.message);
              }
            });
            this.form = res.data.data
          } else {
            this.$message.error(res.data.message);
          }
        });
    },

    handleDelte(id) {
      this.$confirm("此操作将永久删除该解决方案, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const res = await this.$http.post("deviceAlarmSuggestion/delete", {
            id: id,
          });
          if (res.data.code === 200) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getList();
          }
        })
        .catch((err) => {
          console.log(err, 495);
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    //新增按钮
    newBtn() {
      this.labelType = "add";
      this.showDialog = true;
      this.editable = false
      this.form = {};
    },

    // 修改页数大小
    handleSizeChange(val) {
      this.queryInfo.pageSize = val;
      this.getList();
    },

    // 获取当前页面
    handleCurrentChange(val) {
      this.queryInfo.currPage = val;
      this.getList();
    },

    // 当前选择数据
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>

<style scoped lang="less">
.home-class {
  background: #223f6c;
  padding: 10px;
  height: 100%;
  .power-station {
    height: 100%;
    background: #2d5981;
    padding: 8px;
    .form-class {
      padding-right: 50px;
      /* 表单 */
      .el-select{
        width: -webkit-fill-available;
      }
      ::v-deep .el-form-item__label {
        background-color: rgba(255, 255, 255, 0);
        box-sizing: border-box;
        font-family: "微软雅黑", sans-serif;
        color: #333;
        font-size: 16px;
        box-sizing: border-box;
      }
      ::v-deep .el-input__inner {
        border-radius: 5px;
        border: 1px solid rgba(151, 179, 203, 0.55);
        background-color: rgba(255, 255, 255, 0);
        box-sizing: border-box;
        font-family: "Arial", sans-serif;
        color: #333;
        text-align: left;
        line-height: normal;
      }
    }
    .top-form-inline{
      /deep/.el-form-item{
        margin-bottom: 0;
      }
      .top-form{
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    ::v-deep .el-input-group__append {
      background: #409eff;
      color: #fff;
    }
    ::v-deep .el-form-item__label {
      background-color: rgba(255, 255, 255, 0);
      box-sizing: border-box;
      font-family: "微软雅黑", sans-serif;
      color: #ffffff;
      font-size: 16px;
    }
    ::v-deep .el-input__inner {
      border-radius: 5px;
      border: 1px solid rgba(151, 179, 203, 0.55);
      background-color: rgba(255, 255, 255, 0);
      box-sizing: border-box;
      font-family: "Arial", sans-serif;
      color: #f2f2f2;
      text-align: left;
      line-height: normal;
    }
    ::v-deep .el-table--border,
    .el-table--group {
      border: 1px solid rgba(151, 179, 203, 0.55);
    }
    ::v-deep .el-table--border th {
      background-color: #21527e;

      border: 1px solid rgba(151, 179, 203, 0.55);
      box-sizing: border-box;
      font-family: "微软雅黑", sans-serif;
      color: #ffffff;
    }

    ::v-deep .el-table th {
      background-color: #2d5981;
      border: 1px solid rgba(151, 179, 203, 0.55);
      box-sizing: border-box;
      font-family: "微软雅黑", sans-serif;
      color: #ffffff;
    }
    ::v-deep .el-table td {
      background-color: #2d5981;
      border: 1px solid rgba(151, 179, 203, 0.55);
      box-sizing: border-box;
      font-family: "微软雅黑", sans-serif;
      color: #ffffff;
    }
    ::v-deep .el-table__body-wrapper {
      background: #2d5981;
    }
    ::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
      background-color: #223f6c !important;
    }
    ::v-deep .el-pagination {
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
    }
    ::v-deep .el-pagination button:disabled {
      background: rgba(255, 255, 255, 0);
    }
    ::v-deep .el-pager li.active {
      color: #f2f2f2;
      background-color: #00b2e6;
      cursor: default;
    }
    ::v-deep .el-pager li {
      background: none;
      color: #f2f2f2;
    }
    ::v-deep .el-icon {
      color: #fff;
    }

    ::v-deep .el-pagination .btn-prev {
      background: none;
    }
    ::v-deep .el-pagination .btn-next {
      background: none;
    }
    ::v-deep .el-pagination__total {
      color: #fff;
      box-sizing: border-box;
      font-family: "Arial", sans-serif;
      font-size: 12px;
    }
    ::v-deep .el-pagination__jump {
      color: #fff;
      box-sizing: border-box;
      font-family: "Arial", sans-serif;
      font-size: 15px;
    }
    ::v-deep .el-icon-close:before {
      color: #333;
    }
    ::v-deep .el-upload {
      border: 1px solid #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 100px;
      height: 100px;
      line-height: 100px;
      text-align: center;
    }
  }
}

.lis {
  height: 30px;
  line-height: 30px;
  text-align: start;
}
.active {
  background-color: #00b2e6;
  color: #fff;
}

::v-deep .el-table--small .el-table__cell {
  padding: 0 !important;
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #f59a23 !important;
}

::v-deep .el-form-item__label {
  text-align: end !important;
}
::v-deep .el-dialog__body {
  padding: 0 30px !important;
}

// ::v-deep .el-form-item--small.el-form-item {
//   height: 36px !important;
// }
</style>
