import { render, staticRenderFns } from "./alarmSuggestion.vue?vue&type=template&id=1d5594a3&scoped=true"
import script from "./alarmSuggestion.vue?vue&type=script&lang=js"
export * from "./alarmSuggestion.vue?vue&type=script&lang=js"
import style0 from "./alarmSuggestion.vue?vue&type=style&index=0&id=1d5594a3&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d5594a3",
  null
  
)

component.options.__file = "alarmSuggestion.vue"
export default component.exports